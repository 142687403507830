import {rqCreateMultipartRequest, rqCreateRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {
  RQ_DOCUMENT_LOOKUP_API,
  RQ_HIDE_DOCUMENT_API,
  RQ_UPLOAD_DOCUMENT_API,
  RQ_USER_DOCUMENT_API,
} from '@app/reactQuery/apis/constants/rqDocumentAPIConstants';
import {useQuery} from '@tanstack/react-query';

export const RQUseGetDocumentInfo = (payload, options = {}) => {
  return useQuery({
    queryKey: ['rq_document_lookup_api', payload],
    queryFn: async ({queryKey, signal}) => {
      const {id, tokenData} = queryKey[1];
      return rqCreateRequest(
        RQ_DOCUMENT_LOOKUP_API,
        null,
        tokenData?.access_token,
        {id: id},
        signal,
      );
    },
    ...options,
  });
};

export const RQUploadDocument = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_UPLOAD_DOCUMENT_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};

export const RQUserDocument = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_DOCUMENT_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};

export const RQHideDocument = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_HIDE_DOCUMENT_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};
