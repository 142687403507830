import {Suspense, lazy} from 'react';
import {ROUTE_DATENSCHUTZ} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const Datenschutz = lazy(() =>
  import('../../../screens/Datenschutz')
    .then(module => ({default: module.Datenschutz})));

const DatenschutzNavigation = [
  {
    path: ROUTE_DATENSCHUTZ,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Datenschutz />
      </Suspense>
    ),
  },
];

export default DatenschutzNavigation;
