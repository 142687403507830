/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {},
    colors: {
      primary: 'var(--PRIMARY_COLOR)',
      'primary-50': 'var(--PRIMARY_50)',
      'primary-100': 'var(--PRIMARY_100)',
      'primary-200': 'var(--PRIMARY_200)',
      'primary-300': 'var(--PRIMARY_300)',
      'primary-400': 'var(--PRIMARY_400)',
      'primary-500': 'var(--PRIMARY_500)',
      'primary-600': 'var(--PRIMARY_600)',
      'primary-700': 'var(--PRIMARY_700)',
      'primary-800': 'var(--PRIMARY_800)',
      'primary-900': 'var(--PRIMARY_900)',
      secondary: 'var(--SECONDARY_COLOR)',
      'secondary-50': 'var(--SECONDARY_50)',
      'secondary-100': 'var(--SECONDARY_100)',
      'secondary-200': 'var(--SECONDARY_200)',
      'secondary-300': 'var(--SECONDARY_300)',
      'secondary-400': 'var(--SECONDARY_400)',
      'secondary-500': 'var(--SECONDARY_500)',
      'secondary-600': 'var(--SECONDARY_600)',
      'secondary-700': 'var(--SECONDARY_700)',
      'secondary-800': 'var(--SECONDARY_800)',
      'secondary-900': 'var(--SECONDARY_900)',
      tertiary: ' var(--TERTIARY_COLOR)',
      'tertiary-50': 'var(--TERTIARY_50)',
      'tertiary-100': 'var(--TERTIARY_100)',
      'tertiary-200': 'var(--TERTIARY_200)',
      'tertiary-300': 'var(--TERTIARY_300)',
      'tertiary-400': 'var(--TERTIARY_400)',
      'tertiary-500': 'var(--TERTIARY_500)',
      'tertiary-600': 'var(--TERTIARY_600)',
      'tertiary-700': 'var(--TERTIARY_700)',
      'tertiary-800': 'var(--TERTIARY_800)',
      'tertiary-900': 'var(--TERTIARY_900)',
      gray: 'var(--GRAY_COLOR)',
      'gray-50': 'var(--GRAY_50)',
      'gray-100': 'var(--GRAY_100)',
      'gray-200': 'var(--GRAY_200)',
      'gray-300': 'var(--GRAY_300)',
      'gray-400': 'var(--GRAY_400)',
      'gray-500': 'var(--GRAY_500)',
      green: 'var(--GREEN_COLOR)',
      'green-50': 'var(--GREEN_50)',
      'green-200': 'var(--GREEN_200)',
      'green-300': 'var(--GREEN_300)',
      'green-400': 'var(--GREEN_400)',
      'green-500': 'var(--GREEN_500)',
      blue: 'var(--BLUE_COLOR)',
      'blue-50': 'var(--BLUE_50)',
      'blue-200': 'var(--BLUE_200)',
      'blue-300': 'var(--BLUE_300)',
      'blue-400': 'var(--BLUE_400)',
      'blue-500': 'var(--BLUE_500)',
      'blue-600': 'var(--BLUE_600)',
      'blue-700': 'var(--BLUE_700)',
      'blue-800': 'var(--BLUE_800)',
      'blue-900': 'var(--BLUE_900)',
      white: 'var(--WHITE_COLOR)',
      black: 'var(--BLACK_COLOR)',
      background:  'var(--BACKGROUND_COLOR)',
      'feedback-button-color': 'var(--FEEDBACK_BUTTON_COLOR)',
      'text-correction-color': 'var(--TEXT_CORRECTION_COLOR)',
    },
  },
  plugins: [],
  darkMode: 'media',
  variants: {
    extend: {},
  },
};
