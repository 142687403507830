import {getLocalAuth} from '@app/localStorage';
import {redirect} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_LECTORIA} from '../RoutePathConstant';

export const loginLoader = async () => {
  const auth = await getLocalAuth();

  if (auth) {
    return redirect(ROUTE_BASE_ROOT + ROUTE_LECTORIA);
  }
  return auth;
};
