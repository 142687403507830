import {rqCreateMultipartRequest, rqCreateRequest} from './rqHttpsUtils';
import {RQ_USER_ENTER_WAITLIST_API, RQ_USER_FORGOT_PASSWORD_API, RQ_USER_LOGIN_API} from './constants';

export const RQUserLoginAPI = ({email, password, signal}) => {
  return rqCreateRequest(
    RQ_USER_LOGIN_API,
    {
      email: email,
      password: password,
    },
    null,
    null,
    signal,
  );
};
export const RQUserForgotPasswordAPI = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_FORGOT_PASSWORD_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};

export const RQUserWaitListAPI = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_USER_ENTER_WAITLIST_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};
