//Auth APIs
export const USER_LOGOUT_API = {
  url: 'api/v1/user/<%= userId %>/logout',
  method: 'PUT',
  auth: true,
};

export const USER_PROFILE_API = {
  url: 'get_user_info',
  method: 'POST',
  auth: false,
  formData: true,
};

export const USER_PROFILE_UPDATE_API = {
  url: 'update_user_info',
  method: 'POST',
  auth: true,
  formData: true,
};
