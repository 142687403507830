import {Button, Col, Popover} from 'antd';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ROUTE_ACCOUNT} from '@app/navigations/RoutePathConstant';
import {LogoutButton} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout/LogoutButton';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hideLectoriaAssistent} from '@app/redux/actions';

const UserProfile = ({auth, handleHideLectoriaAssistentBackground}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const ShowPopOver = () =>{
    setOpen(true);
  };
  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  const userInfo = auth?.user;
  const initials = (userInfo?.first_name && userInfo?.last_name)
    ? `${userInfo.first_name.charAt(0).toUpperCase()}${userInfo.last_name.charAt(0).toUpperCase()}`
    : '';

  return (
    <Col className={'pt-1'}>
      <Button type={'primary'} onClick={ShowPopOver} shape={'circle'}>{initials}
      </Button>
      <Popover
        content={ (
          <Col className={'flex-col w-80'}>
            <Button className={'p-2 h-12 w-full text-start text-secondary-500 font-semibold  border-b-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-300 rounded-none'}  type={'default'}>
              {userInfo?.email}
            </Button>
            <Button onClick={() =>{
              handleHideLectoriaAssistentBackground();
              navigate(ROUTE_ACCOUNT);
            }}  className={'h-12  p-2 text-start text-secondary-500  font-semibold w-full border-b-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-300 rounded-none'}  type={'default'}>
                            Konto
            </Button>
            <LogoutButton />
          </Col>
        )}
        trigger={'click'}
        open={open}
        placement={'bottomLeft'}
        onOpenChange={handleOpenChange}
      >
      </Popover>
    </Col>
  );
};

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  handleHideLectoriaAssistentBackground: PropTypes.func,

};
const actions = {
  handleHideLectoriaAssistentBackground: hideLectoriaAssistent,
};
const mapStateToProps = state => {
  const auth = selectAuth(state);
  return {auth};
};
export default connect(mapStateToProps, actions)(UserProfile);
