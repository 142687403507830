import {Suspense, lazy} from 'react';
import {ROUTE_IMPRESSUM} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const Impressum = lazy(() =>
  import('../../../screens/Impressum')
    .then(module => ({default: module.Impressum})));

const ImpressumNavigation = [
  {
    path: ROUTE_IMPRESSUM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Impressum />
      </Suspense>
    ),
  },
];

export default ImpressumNavigation;
