import {rqCreateMultipartRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_SAVE_FEEDBACK_API} from '@app/reactQuery/apis/constants';

export const RQSaveFeedbackAPI = async  ({
  formData,
  signal,
}) =>  {
  return rqCreateMultipartRequest(
    RQ_SAVE_FEEDBACK_API,
    {...formData},
    null,
    null,
    signal,
  );
};
