import React, {useEffect, useState} from 'react';
import {Menu} from 'antd';
import * as PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {ROUTE_BASE_ROOT} from '@app/navigations/RoutePathConstant';
import {HeaderMenuList} from '@app/screensComponents/HomeScreen/MainHome/Menu/HeaderMenuList/HeaderMenuList';
import {MenuBuilder} from '@app/screensComponents/HomeScreen/MainHome/Menu/MenuBuilder';

const HeaderMenu = () => {

  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    location.pathname === ROUTE_BASE_ROOT || location.pathname === ''
      ? ROUTE_BASE_ROOT
      : location.pathname.split(ROUTE_BASE_ROOT)[1],
  );

  useEffect(() => {
    if (location) {
      if (currentPath !== location.pathname) {
        setCurrentPath(location.pathname);
      }
    }
  }, [location, currentPath]);

  return (
    <Menu
      mode={'horizontal'}
      className={'text-base font-semibold w-96 flex justify-end border-none'}
      selectable={false}
      items={[
        ...HeaderMenuList.map(menu => MenuBuilder({menu, currentPath})),
      ]}
    />

  );
};

HeaderMenu.propTypes = {
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HeaderMenu;
