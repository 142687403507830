import {ROUTE_LECTORIA} from '@app/navigations/RoutePathConstant';
import {Suspense, lazy} from 'react';
import {authLoader} from '@app/navigations/loader';
import {Spin} from 'antd';

const Lectoria = lazy(() =>
  import('../../../screens/Lectoria')
    .then(module => ({default: module.Lectoria})));

const LectoriaNavigation = [
  {
    path: ROUTE_LECTORIA,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Lectoria />
      </Suspense>
    ),
  },
];

export default LectoriaNavigation;
