import React from 'react';
import {useNavigation} from 'react-router-dom';
import {ContentLayout} from '../ContentLayout';
import {Layout} from 'antd';
import {HeaderLayout} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout';
import {FooterBottomLayout} from '@app/screensComponents/HomeScreen/MainHome/FooterBottomLayout';

const MainLayout = () => {
  const navigation = useNavigation();

  return (
    <Layout>
      <HeaderLayout />

      <ContentLayout navigation={navigation} />

      <FooterBottomLayout />
    </Layout>
  );
};

export default MainLayout;
