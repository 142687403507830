import {AppConfig} from '../config';
import {endsWith} from 'lodash';

const TENANT_ID_KEY = 'APP-Tenant-Id';
const AUTHORIZATION_KEY = 'Authorization';

export const parseNdjsonData = data => {
  if (typeof data === 'object') {
    return [data];
  }

  if (typeof data !== 'string') {
    throw new Error('Unexpected nd-json data type!');
  }
  const rows = data.split(/\n|\n\r/).filter(Boolean);
  return rows.map(row => JSON.parse(row));
};

export const buildHttpRequestHeader = (
  headers = {},
  isAuth = false,
  token = '',
  contentType = 'application/json',
  accept = 'application/json',
) => {
  headers['Content-Type'] = contentType;
  headers['Accept'] = accept; 
  headers[TENANT_ID_KEY] = AppConfig.TENANT_ID;
  if (isAuth) {
    headers[AUTHORIZATION_KEY] = `Bearer ${token}`;
  }

  return headers;
};

export const buildHttpUrl = relativePath => {
  return `${AppConfig.API_BASE_URL}/${relativePath}`;
};

export const buildFormData = ({formData}) => {
  return JSON.stringify(formData);
};

export const buildHttpDocumentViewUrl = ({url = '', token = ''}) => {
  if (!(endsWith(url, '?') || endsWith(url, '&'))) {
    if (url.includes('&')) {
      url = url + '&';
    } else {
      url = url + '?';
    }
  }

  return url + AUTHORIZATION_KEY + '=' + token + '&' + TENANT_ID_KEY + '=' + AppConfig.TENANT_ID;
};
