import dayjs from 'dayjs';

const kbToMB = kb => {
  return (kb / 1024).toFixed(2);
};
export const bytesToMB = bytes => {
  return (bytes / (1024 * 1024)).toFixed(2);
};

const DDMMYYYY = 'DD.MM.YYYY HH:mm [Uhr]';
export const getDayFormat = (date, dateFormat = DDMMYYYY) => {
  return dayjs(date).format(dateFormat);
};
export default kbToMB;
