import {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '@app/navigations/loader';
import {ROUTE_FAQ} from '@app/navigations/RoutePathConstant';

const FAQ = lazy(() =>
  import('../../../screens/FAQ')
    .then(module => ({default: module.FAQ})));

const FAQNavigation = [
  {
    path: ROUTE_FAQ,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <FAQ />
      </Suspense>
    ),
  },
];

export default FAQNavigation;
