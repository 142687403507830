import {Button, Card, Col, Image, Row} from 'antd';
import {CopyrightOutlined} from '@ant-design/icons';
import GermanyFlag from '@app/assets/images/GermanyFlag.svg';
import Lock from '@app/assets/images/lock.svg';
import DSGVOkonform from '@app/assets/images/DSGVO-konform.png';
import {useNavigate} from 'react-router-dom';
import {ROUTE_DATENSCHUTZ, ROUTE_IMPRESSUM} from '@app/navigations/RoutePathConstant';

const FooterBottomLayout = () => {
  const navigate = useNavigate();
  return (
    <Card
      className={'bg-primary-800 rounded-none'}
    >
      <Col>
        <Row className={'text-3xl font-extrabold text-white pl-6 '}>
                    LECTORIA
        </Row>
        <Row className={'pl-6 pb-7 '}>
          <Col span={12}>
            <Row>
              <Col>
                <Row className={'pt-8 pr-3'}>
                  <Image
                    preview={false}
                    width={45}
                    height={45}
                    src={Lock}
                  />
                </Row>
                <Row className={'pt-5 pr-3'}>
                  <Image
                    preview={false}
                    width={45}
                    height={45}
                    src={GermanyFlag}
                  />
                </Row>
                <Row className={'pt-6 pr-3'}>
                  <Image
                    preview={false}
                    width={45}
                    height={45}
                    src={DSGVOkonform}
                  />
                </Row>
              </Col>
              <Col>
                <Row className={'text-base text-white font-bold pt-7 '}>
                            Höchste Datensicherheit
                </Row>
                <Row className={'text-white text-sm'}>
                           Vorbereitung zur ISO 27001
                </Row>
                <Row className={'text-base text-white font-bold pt-7'}>
                            Made in Germany
                </Row>
                <Row className={'text-white text-sm'}>
                            In Berlin ansässig
                </Row>
                <Row className={'text-base text-white font-bold pt-7'}>
                            DSGVO-konform
                </Row>
                <Row className={'text-white text-sm'}>
                           Rechenzentrum in Europa
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className={'text-white text-lg pr-8 pt-4 justify-end font-semibold'}>
              <Button onClick={()=>navigate(ROUTE_DATENSCHUTZ)} type={'link'} className={'!p-0 text-white text-lg font-semibold'}>
                Datenschutz
              </Button>
            </Row>
            <Row className={'text-white text-lg pt-4 pr-8 justify-end font-semibold '}>
              <Button onClick={()=>navigate(ROUTE_IMPRESSUM)} type={'link'} className={'!p-0 text-white text-lg font-semibold'}>
                               Impressum
              </Button>
            </Row>
            <Row className={'text-white text-sm pt-14 pr-8 justify-end'}>
              <CopyrightOutlined className={'pr-2'} />
                            LECTORIA 2024 ALL RIGHTS RESERVED
            </Row>
          </Col>
        </Row>
      </Col>
    </Card>
  );
};

export default FooterBottomLayout;
