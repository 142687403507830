import {get, isEmpty, lowerCase} from 'lodash';
import {createRequest} from './httpUtils';
import {USER_LOGOUT_API, USER_PROFILE_API, USER_PROFILE_UPDATE_API} from './constants';

export const logoutUser = payload => {
  if (isEmpty(get(payload, 'user.id'))) {
    return invalidLogout(payload);
  }
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload.tokenData?.access_token,
    {
      userId: get(payload, 'user.id', 'INVALID_USER'),
      role: lowerCase(get(payload, 'user.role')),
    },
  );
};

export const invalidLogout = payload => {
  return createRequest(
    USER_LOGOUT_API,
    {},
    payload?.tokenData?.access_token,
    {
      userId: 'invalid',
      role: lowerCase(get(payload, 'user.role')),
    },
  );
};

export const getUserProfileAPI = payload => {
  const email = payload?.user?.email;
  const formData = new URLSearchParams();

  formData.append('email', email);

  return createRequest(
    USER_PROFILE_API,
    formData.toString(),
    null,
    null,
    'application/x-www-form-urlencoded',
    'application/json',
    {email},
  );
};

export const updateUserProfileAPI = payload => {

  const email = payload?.email;
  const user_parameter = payload?.user_parameter;
  const parameter_value = payload?.parameter_value;

  const formData = new URLSearchParams();

  formData.append('email', email);
  formData.append('user_parameter', user_parameter);
  formData.append('parameter_value', parameter_value);

  return createRequest(
    USER_PROFILE_UPDATE_API,
    formData.toString(),
    null,
    {email, user_parameter, parameter_value},
    'application/x-www-form-urlencoded',
    'application/json',
    {email},

  );
};
