import {Image, Row} from 'antd';
import LectoriaAssistentBackgroundIconPng from '@app/assets/images/LectoriaAssistentBackgroundIcon.png';
import React from 'react';
import PropTypes from 'prop-types';

const LectoriaAssistentBackgroundIcon = ({children, className = 'w-full', width = '100%', height = '100%'}) => {
  return (
    <Row className={'absolute z-1 w-full h-full'}>
      <Row className={className}>
        <Image preview={false} width={width} height={height} src={LectoriaAssistentBackgroundIconPng} />
      </Row>
      <Row className={'justify-center top-16 absolute inset-0'}>{children}</Row>
    </Row>
  );
};

LectoriaAssistentBackgroundIcon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default LectoriaAssistentBackgroundIcon;
