import {LOCAL_AUTH_STORAGE_KEY} from './constants';
import localforage from 'localforage';
import {isEmpty} from 'lodash';

export const getLocalAuth = async () => {
  try {
    const auth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);

    return !isEmpty(auth?.user) ? auth : null;
  } catch (error) {

    return null;
  }
};

export const removeLocalAuth = async () => {
  try {
    await localforage.removeItem(LOCAL_AUTH_STORAGE_KEY);

    return true;
  } catch (error) {
    return false;
  }
};

export const addLocalAuth = async auth => {
  try {
    await localforage.setItem(LOCAL_AUTH_STORAGE_KEY, auth);
    return auth;
  } catch (error) {
    return null;
  }
};

export const  addUserProfileToLocalAuth = async userProfile => {
  try {
    let localAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);

    if (!localAuth) {
      return null;
    }
  
    const storageUserProfile = userProfile.data[0]?.user_info || userProfile;

    localAuth.user = {...localAuth.user, ...storageUserProfile};

    await localforage.setItem(LOCAL_AUTH_STORAGE_KEY, localAuth);
    return userProfile;
  } catch (error) {

    return null;
  }
};

export const updateUserProfileParameterToLocalAuth = async userParameterAndValue => {
  try {

    let localAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);

    if (!localAuth || !localAuth.user) {

      return null;
    }

    const {user_parameter, parameter_value} = userParameterAndValue;

    localAuth.user = {
      ...localAuth.user,
      [user_parameter]: parameter_value,
    };

    await localforage.setItem(LOCAL_AUTH_STORAGE_KEY, localAuth);

    const updatedLocalAuth = await localforage.getItem(LOCAL_AUTH_STORAGE_KEY);

    return updatedLocalAuth;
  } catch (error) {

    return null;
  }
};
