import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {logoutStart} from '@app/redux/actions';
import {connect} from 'react-redux';
import {selectAuth} from '@app/redux/selectors';
import LogOutIcon from '@app/components/icon/LogOutIcon/LogOutIcon';
import {Button, Typography} from 'antd';

const LogoutButton = ({auth, handleLogoutStart}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Button type={'link'} className={'right-2 mt-3'}   onClick={() => handleLogoutStart(auth)}>
      <Typography.Text onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} className={'font-semibold text-secondary-500 hover hover:text-primary-500'}>
        Abmelden
        <LogOutIcon isHovering={isHovering} className={'pl-2'} width={35}  />
      </Typography.Text>
    </Button>
  );
};

LogoutButton.propTypes = {
  auth: PropTypes.object.isRequired,
  handleLogoutStart: PropTypes.func.isRequired,
};

const actions = {
  handleLogoutStart: logoutStart,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState, actions)(LogoutButton);
