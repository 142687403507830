import {getLocalAuth} from '@app/localStorage';
import {redirect} from 'react-router-dom';
import {ROUTE_BASE_ROOT, ROUTE_LECTORIA, ROUTE_LOGIN} from '../RoutePathConstant';

export const appIndexAuthLoader = async () => {
  const auth = await getLocalAuth();

  if (!auth) {
    return redirect(ROUTE_BASE_ROOT + ROUTE_LOGIN);
  } else {
    return redirect(ROUTE_BASE_ROOT + ROUTE_LECTORIA);
  }
};
