import React from 'react';
import {Col, Layout, Space, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ROUTE_LECTORIA} from '@app/navigations/RoutePathConstant';
import {FeedbackModal} from '@app/screensComponents/HomeScreen/MainHome/FeedbackModal';
import {HeaderMenu} from '@app/screensComponents/HomeScreen/MainHome/Menu/HeaderMenu';
import {UserProfile} from '@app/screensComponents/HomeScreen/MainHome/HeaderLayout/UserProfile';

const {Header} = Layout;
const {Text} = Typography;

const HeaderLayout = () => {
  const navigate = useNavigate();
  return (
    <Header
      className={'bg-white z-10 top-0 w-auto flex justify-between sticky border-2'}
    >
      <Col className={'flex justify-center items-center'}>
        <Text className={'text-3xl cursor-pointer text-secondary font-bold font-sans tracking-wide'} onClick={() => navigate(ROUTE_LECTORIA)}>
             LECTORIA
        </Text>
      </Col>
      <FeedbackModal />
      <Space className={'flex justify-end'}>
        <HeaderMenu />
        <UserProfile />
      </Space>
    </Header>
  );
};

export default HeaderLayout;
