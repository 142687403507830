// base
export const ROUTE_BASE_ROOT = '/'; // *WARN: DO NOT change this
export const ROUTE_BASE_NAME = '/'; // *WARN: DO NOT change this
export const ROUTE_WILDCARD = '*';

export const ROUTE_LOGIN = 'login';
export const ROUTE_FORGOT_PASSWORD = 'forgotpassword';
export const ROUTE_HOME = 'home';

// -- USERS
// dashboard
export const ROUTE_DASHBOARD = 'dashboard';

export const ROUTE_FAQ = 'faq';
export const ROUTE_LECTORIA = 'lectoria';
export const ROUTE_DATENSCHUTZ = 'datenschutz';
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_IMPRESSUM = 'impressum';
