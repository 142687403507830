import {
  HIDE_SPINNER,
  SHOW_ERROR,
  SHOW_SPINNER,
  SHOW_SUCCESS,
} from '../../actionConstants';

export const messages = {
  LOGIN_START: [
    {action: SHOW_SPINNER},
  ],
  LOGIN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Sie haben sich erfolgreich angemeldet', type: 'success'}},
    },
  ],
  LOGIN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Authentication failed!', type: 'error'}},
    },
  ],
  LOGOUT_START: [
    {action: SHOW_SPINNER},
  ],
  LOGOUT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Sie haben sich erfolgreich abgemeldet', type: 'success'}},
    },
  ],
  LOGOUT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Logout failed!', type: 'warn'}},
    },
  ],
  FORGOT_PASSWORD_START: [
    {action: SHOW_SPINNER},
  ],
  FORGOT_PASSWORD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Password reset successful!', type: 'success'}},
    },
  ],
  FORGOT_PASSWORD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Password reset failed!', type: 'error'}},
    },
  ],
  GET_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  GET_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
  ],
  GET_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please try in 5 min to login!', type: 'warn'}},
    },
  ],
  UPDATE_USER_PROFILE_START: [
    {action: SHOW_SPINNER},
  ],
  UPDATE_USER_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Änderungen wurden erfolgreich gespeichert', type: 'success'}},
    },
  ],
  UPDATE_USER_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Profile update failed!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_ROW_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_ROW_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet update failed!', type: 'error'}},
    },
  ],
  CREATE_TIMESHEET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet created successfully!', type: 'success'}},
    },
  ],
  CREATE_TIMESHEET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet create failed!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_STATUS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet status updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_STATUS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet status update failed!', type: 'error'}},
    },
  ],
  UPDATE_TIMESHEET_APPROVER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Approver updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_TIMESHEET_APPROVER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Approver update failed!', type: 'error'}},
    },
  ],
  SUBMIT_TIMESHEET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Timesheet submitted successfully!', type: 'success'}},
    },
  ],
  SUBMIT_TIMESHEET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Timesheet submit failed!', type: 'error'}},
    },
  ],
  CREATE_USER_ALL_FIELDS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User created successfully!', type: 'success'}},
    },
  ],
  CREATE_USER_ALL_FIELDS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User create failed!', type: 'error'}},
    },
  ],
  UPDATE_USER_ALL_FIELDS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_USER_ALL_FIELDS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User update failed!', type: 'error'}},
    },
  ],
  UPDATE_ADMIN_PROFILE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Admin Profile updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_ADMIN_PROFILE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Admin Profile update failed!', type: 'error'}},
    },
  ],
  CREATE_JOB_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job code created successfully!', type: 'success'}},
    },
  ],
  CREATE_JOB_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job code create failed!', type: 'error'}},
    },
  ],
  UPDATE_JOB_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job code updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_JOB_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job code update failed!', type: 'error'}},
    },
  ],
  CREATE_COST_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Cost code created successfully!', type: 'success'}},
    },
  ],
  CREATE_COST_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Cost code create failed!', type: 'error'}},
    },
  ],
  UPDATE_COST_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Cost code updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_COST_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Cost code update failed!', type: 'error'}},
    },
  ],
  UPDATE_IRS_MILEAGE_RATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'IRS Mileage Rate updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_IRS_MILEAGE_RATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'IRS Mileage Rate update failed!', type: 'error'}},
    },
  ],
  UPDATE_OTHER_EXPENSE_TYPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense Type updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_OTHER_EXPENSE_TYPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense Type update failed!', type: 'error'}},
    },
  ],
  CREATE_EXPENSE_TYPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense Type created successfully!', type: 'success'}},
    },
  ],
  CREATE_EXPENSE_TYPE_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense Type create failed!', type: 'error'}},
    },
  ],
};
