export const RQ_USER_LOGIN_API = {
  url: 'login',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_USER_FORGOT_PASSWORD_API = {
  url: 'new_password',
  method: 'POST_FORM',
  auth: false,
};

export const RQ_USER_ENTER_WAITLIST_API = {
  url: 'enter_waitlist',
  method: 'POST_FORM',
  auth: false,
};
