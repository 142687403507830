import {rqCreateMultipartRequest} from '@app/reactQuery/apis/rqHttpsUtils';
import {RQ_TEXT_CORRECTION_API} from '@app/reactQuery/apis/constants';

export const RQTextCorrection = async ({
  formData,
  tokenData,
  signal,
  email,
}) => {
  return rqCreateMultipartRequest(
    RQ_TEXT_CORRECTION_API,
    {...formData},
    null,
    {email:email},
    signal,
  );
};
