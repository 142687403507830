import {DashboardNavigation} from './screensNavigation/Dashboard';
import {createBrowserRouter} from 'react-router-dom';
import {MainHome} from '../screensComponents/HomeScreen/MainHome';
import {ErrorPage} from '../components/common/ErrorPage';
import {IndexNavigation} from './screensNavigation/Index/index';
import React from 'react';
import {PublicRoute} from './PublicRoute';
import {LoginNavigation} from './screensNavigation/Login';
import {DashboardIndexNavigation} from './screensNavigation/DashboardIndex';
import {ROUTE_BASE_NAME} from './RoutePathConstant';
import {FAQNavigation} from '@app/navigations/screensNavigation/FAQNavigation';
import {LectoriaNavigation} from '@app/navigations/screensNavigation/LectoriaNavigation';
import {DatenschutzNavigation} from '@app/navigations/screensNavigation/DatenschutzNavigation';
import {AccountNavigation} from '@app/navigations/screensNavigation/AccountNavigation';
import {ImpressumNavigation} from '@app/navigations/screensNavigation/ImpressumNavigation';

const AuthRoute = [
  ...DashboardNavigation,
  ...FAQNavigation,
  ...LectoriaNavigation,
  ...DatenschutzNavigation,
  ...AccountNavigation,
  ...ImpressumNavigation,
];

export const AppRouter = createBrowserRouter([
  IndexNavigation,
  ...LoginNavigation,
  {
    path: '',
    element: <MainHome />,
    errorElement: <ErrorPage />,
    children: [
      DashboardIndexNavigation,
      ...AuthRoute,
      ...PublicRoute,
    ],
  },
], {
  basename: ROUTE_BASE_NAME,
});
