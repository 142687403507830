import {ROUTE_DATENSCHUTZ, ROUTE_FAQ, ROUTE_LECTORIA} from '@app/navigations/RoutePathConstant';

export const HeaderMenuList = [
  {
    key: ROUTE_LECTORIA,
    path: ROUTE_LECTORIA,
    label: 'Lectoria',
  },
  {
    key:  ROUTE_FAQ,
    path:  ROUTE_FAQ,
    label: 'FAQs',
  },
  {
    key: ROUTE_DATENSCHUTZ,
    path: ROUTE_DATENSCHUTZ,
    label: 'Datenschutz',
  },
];
