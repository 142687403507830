import {Image} from 'antd';
import LogOutSvg from '@app/assets/images/LogOutIcon.svg';
import LogOutPngHover from '@app/assets/images/LogOutIconHover.svg';
import React from 'react';
import PropTypes from 'prop-types';

const LogOutIcon = ({className = '', width = 25, height = 25, onMouseEnter, onMouseLeave, isHovering})  => {

  return (
    <Image  className={className} preview={false}  width={width} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} height={height} src={isHovering? LogOutPngHover : LogOutSvg }  />
  );
};

LogOutIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isHovering: PropTypes.bool,
};

export default LogOutIcon;
