export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const DO_NOTHING = 'DO_NOTHING';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_ERROR = 'SHOW_ERROR';

export const GLOBAL_FAIL = 'GLOBAL_FAIL';
export const SHOW_LECTORIA_ASSISTENT = 'SHOW_LECTORIA_ASSISTENT';

export const HIDE_LECTORIA_ASSISTENT ='HIDE_LECTORIA_ASSISTENT';
