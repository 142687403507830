import React, {useState} from 'react';
import {Button, FloatButton, Input, Modal, Radio, Row, Space, Typography} from 'antd';
import {useMutation} from '@tanstack/react-query';
import PropTypes from 'prop-types';
import {selectAuth} from '@app/redux/selectors';
import {connect} from 'react-redux';
import {RQSaveFeedbackAPI} from '@app/reactQuery/apis';

const {Text} = Typography;

const FeedbackModal = ({auth}) => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState();
  const [comment, setComment] = useState();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const mutationSaveFeedback = useMutation({
    mutationFn: payload => RQSaveFeedbackAPI(payload),
    onSuccess: (response, payload) => {
      setFeedbackSubmitted(true);
    },
    onError: () => {},
  });

  const saveFeedback = () => {
    if (auth?.user?.email) {
      mutationSaveFeedback.mutate({
        formData: {
          email: auth?.user?.email,
          rating: rating,
          comment: comment,
        },
      });
    }
  };

  return (
    <>
      <FloatButton
        onClick={showModal}
        description={(
          <Text className={'w-56 py-1.5 rounded-t-xl rounded-b-none bg-feedback-button-color text-white text-lg flex justify-center items-center'}>
                Feedback
          </Text>
        )}
        className={'bottom-2/3 w-56 -rotate-90 rounded-3xl bg-green-400 fill-green-50 -right-24'}
      />

      <Modal
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={900}
        className={'justify-center items-center flex'}>

        { !feedbackSubmitted && (
          <>
            <Row className={'justify-center pt-6'}>
              <Text className={'text-2xl font-bold text-secondary'}>Ihre Meinung ist uns wichtig.</Text>
            </Row>
            <Row className={'justify-center font-normal text-secondary text-base pt-6'}>
                  Würden Sie sich bitte einen Moment Zeit nehmen, um uns Ihr Feedback mitzuteilen?
              <Typography.Text className={'pt-6 font-semibold text-lg text-secondary-500'}>
                    Wie würden Sie Ihre heutige Erfahrung mit lectoria bewerten?
              </Typography.Text>
            </Row>
            <Row className={'justify-center pt-6 '}>
              <Typography.Text className={'pr-4 font-bold'}>Sehr schlecht</Typography.Text>
              <Radio.Group type={'radio'} value={rating} onChange={e => setRating(e.target.value)} size={'large'} buttonStyle={'solid'}>
                <Radio value={1}>1</Radio>
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={5}>5</Radio>
              </Radio.Group>
              <Typography.Text className={'font-bold'}>Sehr gut</Typography.Text>
            </Row>
            <Row className={'justify-center pt-6'}>
              <Typography.Text className={'justify-center font-semibold text-lg text-secondary-500'}>Warum haben Sie diese Bewertung gewählt?</Typography.Text>
              <Typography.Text className={'pl-1 mt-1 text-secondary'}>(optional)</Typography.Text>
            </Row>
            <Row className={'w-full flex justify-center pt-6 px-20 items-center align-middle'}>
              <Input
                variant={'borderless'}
                className={'border-b-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-300 rounded-none'}
                placeholder={'Schreiben Sie bitte Ihre Antwort hier...'}
                onChange={e => setComment(e.target.value)}
              />
            </Row>
            <Row className={'pt-8 pb-8 justify-center'}>
              <Button
                type={'primary'}
                size={'large'}
                className={'rounded-md px-7'}
                onClick={() => {
                  saveFeedback();
                }}>
                    Bewertung absenden
              </Button>
            </Row>
          </>
        )}
        {feedbackSubmitted && (
          <Row className={'w-full'}>
            <Row className={'justify-center pt-4 w-full'}>
              <Text className={'text-xl text-secondary-500 font-bold'}>Feedback eingereicht.</Text>
            </Row>
            <Row className={'flex justify-center w-full pt-4'}>
              <Text className={'text-sm text-secondary-500'}>Vielen Dank, dass Sie sich die Zeit genommen haben, uns Ihr Feedback zu geben..</Text>
            </Row>
            <Row className={'justify-center w-full pt-7'}>
              <Text className={'text-base text-secondary-500'}>Melden Sie sich für unseren Newsletter an, um über Neuerscheinungen und</Text>
            </Row>
            <Row className={'justify-center w-full'}>
              <Text className={'text-sm text-secondary-500'}>Updates auf dem Laufenden zu bleiben</Text>
            </Row>
            <Space className={'pt-5 w-full flex justify-center'}>
              <Space.Compact>
                <Input
                  size={'large'}
                  placeholder={'Ihre E-Mail Adresse'}
                  className={'w-96 h-10 rounded-lg'}
                />
                <Button
                  size={'small'}
                  type={'primary'}
                  className={'w-32 h-10 rounded-lg text-base font-bold'}
                >
                    Absenden
                </Button>
              </Space.Compact>
            </Space>
            <Row className={'justify-center w-full pt-4 pb-6'}>
              <Text className={'text-sm text-secondary-500'}>Keine Sorge, wir spammen nicht. Wir werden Sie nur über relevante Themen informieren.</Text>
            </Row>
          </Row>
        )}
      </Modal>
    </>
  );
};

FeedbackModal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapStateToProps)(FeedbackModal);
