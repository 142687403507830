import React from 'react';
import {Outlet} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {Layout} from 'antd';
import {connect} from 'react-redux';
import {selectGlobal} from '@app/redux/selectors';
import LectoriaAssistentBackgroundIcon
  from '@app/components/icon/LectoriaAssistentBackgroundIcon/LectoriaAssistentBackgroundIcon';

const {Content} = Layout;

const ContentLayout = ({global}) => {
  return (
    <Content className={`bg-background ${
      global.showLectoriaAssistent ? 'bg-background': 'bg-background'
    }`}>
      {global.showLectoriaAssistent && <LectoriaAssistentBackgroundIcon />}
      <Outlet />
    </Content>
  );
};

ContentLayout.propTypes = {
  navigation: PropTypes.any,
  global: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const global = selectGlobal(state);
  return {global};
};

export default connect(mapStateToProps)(ContentLayout);
