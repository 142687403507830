import {ajax} from 'rxjs/ajax';
import {isEmpty, template} from 'lodash';
import {buildHttpRequestHeader, buildHttpUrl} from '@app/utils';

export const createMultipartRequest = (api, body, token, params) => {
  return create(api, body, token, params, 'multipart/form-data');
};

export const createRequest = (api, body, token, params, contentType ='application/json', accept='application/json', filter = {}) => {
  return create(api, body, token, params, contentType, accept, filter);
};

const create = (api, body, token = '', params, contentType, accept, filter) => {
  let url = api.url;
  if (params !== null && params !== undefined) {
    url = template(url)(params);
  }

  if (api.search) {
    url = `${url}${new URLSearchParams(filter).toString()}`;
  }

  if (isEmpty(token)) {
    token = '';
  }

  const headers = buildHttpRequestHeader({}, api.auth, token, contentType, accept);

  if (api.method === 'POST' || api.method === 'PUT' || api.method === 'POST_FORM') {
    return ajax({
      url: buildHttpUrl(url),
      method: api.method,
      headers: headers,
      crossDomain: true,
      withCredentials: false,
      body: body,
    });
  } else {
    return ajax({
      url: buildHttpUrl(url),
      method: api.method,
      headers: headers,
    });
  }
};
