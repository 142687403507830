import React from 'react';
import {RootNavigator} from '@app/navigations';
import {App as AntdApp, ConfigProvider} from 'antd';
import {PrimaryTheme} from '@app/style';
import {QueryClientProvider} from '@tanstack/react-query';
import configureStore from '@app/redux/store/configureStore';
import {Provider} from 'react-redux';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import GlobalComponent from '@app/components/global/GlobalComponent';
import MainProvider from '@app/navigations/provider/MainProvider';
import {getQueryClient} from '@app/reactQuery/rqClient';
import './App.less';

const store = configureStore();

const App = () => {
  return (
    <QueryClientProvider client={getQueryClient()}>
      <Provider store = {store}>
        <ConfigProvider theme={PrimaryTheme}>
          <MainProvider>
            <AntdApp>
              <GlobalComponent />
              <RootNavigator />
              <ReactQueryDevtools />
            </AntdApp>
          </MainProvider>
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
